/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Image from "../../../../components/Image";
import imgEN from "./img_en.gif";
import imgJA from "./img_ja.gif";
import { getI18n, Trans, useTranslation } from "react-i18next";

const Process = (): JSX.Element => {
  const { t } = useTranslation();
  const i18n = getI18n();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"secondary.main"}
          align={"center"}
        >
          <Trans>How we work?</Trans>
        </Typography>
        <Typography
          variant={"h4"}
          gutterBottom
          align={"center"}
          sx={{ fontWeight: 700 }}
        >
          <Trans>Our process</Trans>
        </Typography>
      </Box>
      <Box>
        <Image src={i18n.language === "en" ? imgEN : imgJA} />
      </Box>
    </Box>
  );
};

export default Process;
